<template>
  <div class="container mx-auto content-container">
    <div class="flex flex-col gap-4 bg-white lg:p-8 rounded-xl mx-auto mt-20">
      <FwbHeading tag="h3" class="text-center border-b border-gray-300 p-4">
        {{ $t("log_in_to_sendchallenges") }}
      </FwbHeading>
      <NuxtPage />
    </div>
  </div>
</template>

<script setup lang="ts">
import FwbHeading from "~/components/Flowbite/Typography/FwbHeading.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const route = useRoute();

useHead({
  title: "Log in | SendChallenges",
});

useHead({
  title: t("log_in_to_sendchallenges"),
});

definePageMeta({
  middleware: "not-logged-in",
});

if (route.matched.length === 1) {
  navigateTo("/login/magic-link/request");
}
</script>
